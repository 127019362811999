/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { 
  MAIN_ROWS, 
  AUDIENCE_ROWS, 
  CAMPAIGNS_ROWS, 
  REPORTS_ROWS, 
  SETTINGS_ROWS, 
  PREMIUM_RESOURCES 
} from "./sidebar-config";
import './sidebar.scss';



const RowItem = ({ id, label, icon, isActive, onSelect, route }) => (
  <li onClick={() => onSelect(id)}>
    <Link to={route}>
      <img src={icon} width={18} alt={label} />
      {label}
    </Link>
  </li>
);

class RSidebar extends React.PureComponent {
  state = {
    activeRowId: "",
    user_data: JSON.parse(localStorage.getItem('user-data')),
    hasActive: '',
    count: 1
  };

  componentDidMount() {
    this.setInitialRow();
  }

  componentWillUpdate() {
    let { count } = this.state;
    if(count === 1) {
      // if (user_data.subscriptionData.hasPaid === false) {
      //   this.setState({ hasActive : 'disabledCode' })
      // }
      count += 1
    }
    
  }

  setInitialRow = () => {
    const rows = [...MAIN_ROWS, ...AUDIENCE_ROWS, ...CAMPAIGNS_ROWS, ...REPORTS_ROWS, ...SETTINGS_ROWS, ...PREMIUM_RESOURCES];
    const row =
      rows.find((item) => window.location.pathname.includes(item.route)) ||
      rows[0];
    this.setState({ activeRowId: row.id });
  };

  onRowSelect = (id) => {
    this.setState({ activeRowId: id });
  };

  mapRowConfigToItem = (row, activeRowId) =>
    row.map((config) => (
      <RowItem
        key={config.id}
        isActive={config.id === activeRowId}
        onSelect={this.onRowSelect}
        {...config}
      />
    ));

  render() { 

    return (
      <aside className="sidenav navbar navbar-vertical navbar-expand-xs py-2 fixed-end d-md-block d-none" id="sidenav-main">

      <div className="w-auto story_rside mr-10" id="sidenav-collapse-main">
        <div className="">
          <p class="category_header">Insights from the community</p>
          <p id="category">
            <span>Cyber Security</span><br/>
            <span><Link to={"/stories/revermonk/the-cybersecurity-funding-bubble-hasnt-burst----but-its-starting-to-deflate"}>The cyber security funding bubble hasn’t burst but it’s starting to deflate.</Link></span>
          </p>
          <p id="category">
            <span>Distributed System</span><br/>
            <span><Link to={"/stories/revermonk/introduction-to-distributed-systems-4helz1t4gzx"}>The Ultimate Guide to Understanding Distributed Systems</Link></span>
          </p>
          <p id="category">
            <span>Artificial Intelligence</span><br/>
            <span>
              <Link to={"/stories/revermonk/xai-elon-musks-ai-startup-to-release-first-ai-model-to-select-group-sl1x0ds0shm"}>
               xAI, Elon Musk's AI Startup, to Release First AI Model to Select Group.
              </Link>
            </span>
          </p>
          <p id="category">
            <span>Machine Learning</span><br/>
            <span>
              <Link to={"/stories/revermonk/why-not-all-vcs-are-ready-to-embrace-ai-powered-investment-tools"}>
                Why not all VCs are ready to embrace AI-powered investment tools
              </Link>
            </span>
          </p>
          <p id="category">
            <span>DevOps</span><br/>
            <span>
              <Link to={"/stories/revermonk/devops"}>Devops - rapidly growing field</Link>
            </span>
          </p>
        </div>
      
        <div className="mr-10">
          <p class="category_header"><b className="_hash">#</b><b className="_tag">Hashtags</b></p>
          <p><Link to={"/tags?type=artificial-intelligence"}><span className="rside_tags theme-text-color">#artificial-intelligence</span></Link></p>
          <p><Link to={"/tags?type=blockchain"}><span className="rside_tags theme-text-color">#blockchain</span></Link></p>
          <p><Link to={"/tags?type=ethereum"}><span className="rside_tags theme-text-color">#ethereum</span></Link></p>
          <p><Link to={"/tags?type=virtual-reality"}><span className="rside_tags theme-text-color">#virtual-reality</span></Link></p>
        </div>
      </div>

    </aside> 
    )
  }
}

export default RSidebar;
