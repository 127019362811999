import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ScrollToTopOnNavigation } from "./../Components/Common";
import Loader from "../Components/PageLoader";

const Grossary = lazy(() => import("../Views/Grossary"))
const Home = lazy(() => import("../Views/Home/Home"))
const Author = lazy(() => import("../Views/Home/Author"))
const About = lazy(() => import("../Views/Common/About"))
const ContactUs = lazy(() => import("../Views/Common/ContactUs"))
const Events = lazy(() => import("../Views/Common/Events"))
const Membership = lazy(() => import("../Views/Common/Membership"))
const Stories = lazy(() => import("../Views/Stories"))
const Dashboard = lazy(() => import("../Views/Dashboard"))
const StoryDetails = lazy(() => import("../Views/Stories/StoryDetails"))
const Tags = lazy(() => import("../Views/Stories/Tags"))
const Signup = lazy(() => import("../Views/User/Signup"))
const Login = lazy(() => import("../Views/User/Login"))
const PageNotFound = lazy(() => import("../Views/Common/PageNotFound"))
const Sponsers = lazy(() => import("../Views/Common/Sponsers"))


function MainRouter() {
  return (
    <BrowserRouter>
      <ScrollToTopOnNavigation />
      <ToastContainer />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/:author" element={<Author />} />
          <Route path="/grossary" element={<Grossary />} />
          <Route path="/stories" element={<Stories />} />
          <Route path="/stories/:user/:slug" element={<StoryDetails />} />
          <Route path="/tags" element={<Tags />} />
          <Route path="/dashboard/account" element={<Dashboard />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/events" element={<Events />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/sponsers" element={<Sponsers />} /> 
          <Route path="/signup" element={<Signup />} /> 
          <Route path="/login" element={<Login />} /> 
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default MainRouter;
