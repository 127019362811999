import React, { useState } from 'react';
import * as imgs from "../../../Assets/img";
import { toastSuccess, toastError  } from "../";
import { Link } from "react-router-dom";
import * as logos from "../../../Assets/img/logos"
import { useSubscriberMutation } from '../../../Service/services';
import "./footer.scss"


export default function Footer() {
  let [email, setEmail] = useState()
  let [subscribe] = useSubscriberMutation()


    // let year = () => {
    //   return document.write(new Date().getFullYear())
    // }

    let onInputChange = (e, stateKey) => {
      const targetValue =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
  
      switch (stateKey) {
        case "email":
          setEmail(targetValue)
          break;
        default:
          return targetValue
      }
    }

    let handleSubscribe = () => {
      if(email === undefined || email === '') 
        toastError(`Email can't empty.`)
     
        subscribe({email: email})
        .then((res) => {
          if (res.data.data) {
            toastSuccess(res.data.message);
            setEmail()
          } else {
            toastError("error");
          }
        })
        .catch((err) => {
          console.log('[Error] ', err.message)
          toastError("Please enter correct email.");
        })
        .finally(() => {
          console.log('subscribe')
        });
    };
   
    return (
        <React.Fragment>

          <section className="my-5 pt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-6 m-auto">
                  <h4>
                    Sign Up For Our Free&nbsp;
                    <span className="relative">
                      <svg className="absolute left-0 right-0 bottom-0 transform scale-x-110 w-full" style={{height:"6px", maxHeight:"6px"}} preserveAspectRatio="none" viewBox="0 0 138.2 6.4" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="M137.7 6.1c.2-.1.4-.4.5-.7s0-.7-.1-.9c-.3-.4-.7-.6-1-.7-.2 0-.5-.1-.9-.1-.4-.1-1-.1-1.6-.2-1.3-.1-3.1-.3-5.1-.5-3.9-.4-8.7-.8-12.5-1.1-4.1-.3-11.4-.8-15.8-1.1C96.9.6 83.4.1 79 .1 54.4-.2 30.2.6 13.8 2.3c-2.4.3-5.7.7-8.3 1-1.3.2-2.5.4-3.3.6-.5 0-.8.1-1.1.2-.1 0-.2 0-.3.1 0 0-.1 0-.2.1l-.2.2c-.1-.1-.4.2-.4.6 0 .7.5 1 .7 1 .2.1.4.1.5.1h1c.9 0 2.3-.2 4.4-.5 4.9-.6 8.6-1 13.2-1.3 20.6-1.6 51.1-2.1 73.6-1.1 12.5.6 25 1.4 37.3 2.7 2 .2 4.1.4 4.8.4M6.5 5.2l.1.5z" fill="#4881ED" fillRule="evenodd"></path></svg>
                      Newsletter!
                    </span> 
                  </h4>
                  <p className="mb-4">
                    We are excited to offer you a platform to connect with other like-minded individuals and build meaningful relationships.
                  </p>
                  <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12">
                      <div className="input-group">
                        <input 
                          type="text" 
                          className="form-control mb-sm-0" 
                          placeholder="Email Here..." 
                          defaultValue={email}
                          onChange={(e) => onInputChange(e, 'email')}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">
                      <span 
                        className="btn btn-sm mb-0 h-100 position-relative z-index-2 subscriber-button-text"
                        onClick={() => handleSubscribe()}
                      >
                        Subscribe
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 ms-auto subscriber-image">
                  <div className="position-relative">
                    <img className="max-width-50 w-100 position-relative z-index-2" src={imgs.sign_up} alt="signup" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <footer className="footer py-5 bg-gradient-dark position-relative overflow-hidden"> */}
          <footer className="footer py-5 position-relative overflow-hidden">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 me-auto mb-lg-0 mb-4 text-lg-start text-center">
                  <h6 className="text-white font-weight-bolder text-uppercase mb-lg-4 mb-3">
                    <img src={logos.revermonk_logo} height="35" alt="Revermonk" />
                  </h6>
              
                  <ul className="nav flex-row ms-n3 justify-content-lg-start justify-content-center mb-4 mt-sm-0">
                    <li className="nav-item">
                      <Link to="/">
                        <span className="nav-link text-white opacity-8">
                          Home
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/about">
                        <span className="nav-link text-white opacity-8">
                          About
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link text-white opacity-8" href="https://revermonk.blogspot.com/" target="_blank" rel="noopener noreferrer">
                        Blog
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link to="/grossary" className="nav-link text-white opacity-8">
                        Grossary
                      </Link>
                    </li>
                  </ul>
                  <p className="text-sm text-white opacity-8 mb-0">
                    Copyright © 2024 Revermonk. Made with love by community
                  </p>
                </div>
                <div className="col-lg-6 ms-auto text-lg-end text-center">
                  <p className="mb-5 text-lg footer-header-text font-weight-bold">
                    Connecting People, Building Decentralize Communities
                  </p>
                  {/* <a role="button" target="_blank" className="text-white me-xl-4 me-4 opacity-5">
                    <span className="fab fa-dribbble"></span>
                  </a>
                  <a role="button" target="_blank" className="text-white me-xl-4 me-4 opacity-5">
                    <span className="fab fa-twitter"></span>
                  </a>
                  <a role="button" target="_blank" className="text-white me-xl-4 me-4 opacity-5">
                    <span className="fab fa-pinterest"></span>
                  </a>
                  <a role="button" target="_blank" className="text-white opacity-5">
                    <span className="fab fa-github"></span>
                  </a> */}
                </div>
              </div>
            </div>
          </footer>

          {/* <div style={{textAlign: 'center', padding: '1em', backgroundColor: 'black', color: 'white'}}>
            Note: Website is beta, content will continue to be updated soon.
          </div> */}
        </React.Fragment>
    )
}
