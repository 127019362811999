

export const MAIN_ROWS = [
  {
    id: "my_dashboard",
    label: "My Dashboard",
    route: "/dashboard"
  },
  {
    id: "account-setup",
    label: "Account Setup",
    route: "/code-setup"
  }
];

export const AUDIENCE_ROWS = [
  {
    id: "segments",
    label: "Segments",
    route: "/segments"
  }
];

export const REPORTS_ROWS = [
  {
    id: "campaign-report-list",
    label: "Campaign Reports",
    route: "/campaign-report-list"
  },
  {
    id: "c-Reports",
    label: "User Reports",
    route: "/reports"
  }
];


export const CAMPAIGNS_ROWS = [
  {
    id: "one-time-listing",
    label: "All Campaigns",
    route: "/one-time-listing"
  },
  {
    id: "campaign-option",
    label: "Create Campaigns",
    route: "/campaign-option"
  },
];

export const SETTINGS_ROWS = [
  {
    id: "integration-page",
    label: "Integrations",
    route: "/integration-page"
  },
  {
    id: "my-account",
    label: "My Account",
    route: "/settings"
  },
];

export const PREMIUM_RESOURCES = [
  {
    id: "premium-resources",
    label: "Premium Resources",
    route: "/premium-resources"
  }
];