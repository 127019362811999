import React from 'react';
import './PageLoader.scss';


const PageLoader = () => ( 
  <div className="loader-box">
    <div className="loader-content">
      <div className="loader-image"></div>
      <div className="loader-text">
        <div className="loader-author"></div>
        <div className="loader-title"></div>
      </div>
    </div>
  </div>

)


export default PageLoader;
