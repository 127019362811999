import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { monkServices } from '../Service/services'


export const store = configureStore({
  reducer: {
    [monkServices.reducerPath]: monkServices.reducer
  },
  
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(monkServices.middleware),
})

setupListeners(store.dispatch)
