/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import './sidebar.scss';
import { FaCircleUser } from 'react-icons/fa6';


export default function AuthorSidebar() {
  let [author, setAuthor] = useState()
  let location = useLocation()

  useEffect(() => {
    setAuthor(location.pathname.split("/")[2])
  }, [])


  return (
    <aside className="sidenav navbar navbar-vertical navbar-expand-xs py-2 fixed-end ms-3 d-md-block d-none" id="sidenav-main">
      
      <div className="w-auto story_rside mr-10" id="sidenav-collapse-main">
        <div className="sidenav_category pd-0">
          <span style={{marginBottom: "2rem", backgroundImage: "linear-gradient(to right, #50D3D1, #6CD1B6)", position: "relative", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-end", width: "100%", height: "7em"}}>
            <FaCircleUser size={50} style={{margin: "-25px", border: "4px solid #fff", borderRadius: "50%", backgroundColor: "#fff" }} />
          </span>
          <p style={{color: "#009DEA", fontFamily: 600}}>@{author}</p>
          {/* <p><span className="rside_tags">Writer</span></p> */}
          <p>writer</p>
        </div>
      </div>
      
      <div className="w-auto story_rside mr-10" id="sidenav-collapse-main">
        <div className="">
          <p class="category_header">Insights from the community</p>
          <p id="category">
            <span>Cyber Security</span><br/>
            <span><Link to={"/stories/revermonk/the-cybersecurity-funding-bubble-hasnt-burst----but-its-starting-to-deflate"}>The cyber security funding bubble hasn’t burst but it’s starting to deflate.</Link></span>
          </p>
          <p id="category">
            <span>Distributed System</span><br/>
            <span><Link to={"/stories/revermonk/introduction-to-distributed-systems-4helz1t4gzx"}>The Ultimate Guide to Understanding Distributed Systems</Link></span>
          </p>
          <p id="category">
            <span>Artificial Intelligence</span><br/>
            <span>
              <Link to={"/stories/revermonk/xai-elon-musks-ai-startup-to-release-first-ai-model-to-select-group-sl1x0ds0shm"}>
               xAI, Elon Musk's AI Startup, to Release First AI Model to Select Group.
              </Link>
            </span>
          </p>
          <p id="category">
            <span>Machine Learning</span><br/>
            <span>
              <Link to={"/stories/revermonk/why-not-all-vcs-are-ready-to-embrace-ai-powered-investment-tools"}>
                Why not all VCs are ready to embrace AI-powered investment tools
              </Link>
            </span>
          </p>
          <p id="category">
            <span>DevOps</span><br/>
            <span>
              <Link to={"/stories/revermonk/devops"}>Devops - rapidly growing field</Link>
            </span>
          </p>
        </div>
      
        <div className="mr-10">
          <p class="category_header"><b className="_hash">#</b><b className="_tag">Hashtags</b></p>
          <p><Link to={"/tags?type=artificial-intelligence"}><span className="rside_tags theme-text-color">#artificial-intelligence</span></Link></p>
          <p><Link to={"/tags?type=blockchain"}><span className="rside_tags theme-text-color">#blockchain</span></Link></p>
          <p><Link to={"/tags?type=ethereum"}><span className="rside_tags theme-text-color">#ethereum</span></Link></p>
          <p><Link to={"/tags?type=virtual-reality"}><span className="rside_tags theme-text-color">#virtual-reality</span></Link></p>
        </div>
      </div>

    </aside> 
  )

}


