import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
let token = localStorage.getItem('user-data') !== null ? JSON.parse(localStorage.getItem('user-data')) : null

export const monkServices = createApi({
  reducerPath: "monkServices",

  // The base query to request data.
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_APIBASE_URL,
  }),

  // The set of operations that we want to perform against the server.
  endpoints: (builder) => ({

    /*********************************************************************************************************************
     * @user management
     * 
     *********************************************************************************************************************/
    /********** @user login **********/
    login: builder.mutation({
        query: (body) => {
            return {
                url: `/login`,
                responseHandler: (response) => response.json(),
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                },
                body: body
            }
        },
    }),

    /********** @user signup **********/
    signup: builder.mutation({
        query: (body) => {
            return {
                url: `/signUp`,
                responseHandler: (response) => response.json(),
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                },
                body: body
            }
        },
    }),

    /********** @get user profile **********/
    profile: builder.mutation({
        query: () => {
            return {
                url: `/getUser`,
                responseHandler: (response) => response.json(),
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: token.data.data.accessToken
                }
            }
        },
    }),

    /********** @update user profile **********/
    updateUserProfile: builder.mutation({
        query: (body) => {
            return {
                url: `/updateUser`,
                responseHandler: (response) => response.json(),
                method: "PUT",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: token.data.data.accessToken
                },
                body: body
            }
        },
    }),

    /********** @user dashboard summary **********/
    userDashboardSummary: builder.mutation({
        query: () => {
            return {
                url: `/userDashboardSummary`,
                responseHandler: (response) => response.json(),
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: token.data.data.accessToken
                }
            }
        },
    }),


    /**************************************************************************************************************************
     * @story management
     *************************************************************************************************************************/
    /********** @save story **********/
    saveStory: builder.mutation({
        query: (body) => {
            return {
                url: `/addStories`,
                responseHandler: (response) => response.json(),
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: token.data.data.accessToken
                },
                body: body
            }
        },
    }),

    /********** @get stories **********/
    getStories: builder.mutation({
        query: () => {
            return {
                url: `/getStories`,
                responseHandler: (response) => response.json(),
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                },
            }
        },
    }),

    /********** @get user stories **********/
    getUserStories: builder.mutation({
        query: () => {
            return {
                url: `/getUserStories`,
                responseHandler: (response) => response.json(),
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: token.data.data.accessToken
                },
            }
        },
    }),

    /********** @get individual story **********/
    individualStory: builder.mutation({
        query: (body) => {
            return {
                url: `/getIndividualStories`,
                responseHandler: (response) => response.json(),
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                },
                body: body
            }
        },
    }),

    /********** @get like story **********/
    likeStory: builder.mutation({
        query: (body) => {
            return {
                url: `/storyLike/${body.storyId}`,
                responseHandler: (response) => response.json(),
                method: "GET",
                headers: {
                    // "Content-type": "application/json; charset=UTF-8",
                    Authorization: token.data.data.accessToken
                },
                // body: body
            }
        },
    }),

    /********** @story comments **********/
    commentsOnStory: builder.mutation({
        query: (body) => {
            return {
                url: `/commentsOnStory`,
                responseHandler: (response) => response.json(),
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: token.data.data.accessToken
                },
                body: body
            }
        },
    }),

    /********** @get comment list **********/
    commentsList: builder.mutation({
        query: (body) => {
            return {
                url: `/commentsList/${body.storyId}`,
                responseHandler: (response) => response.json(),
                method: "GET",
                headers: {
                    // "Content-type": "application/json; charset=UTF-8",
                },
            }
        },
    }),


    /********** @upload story photos **********/
    uploadStoryPhotos: builder.mutation({
        query: (body) => {
            return {
                url: `/uploadFile`,
                responseHandler: (response) => response.json(),
                method: "POST",
                headers: {
                    //"Content-type": "application/json; charset=UTF-8"
                    // "Content-Type": "multipart/form-data",
                    Authorization: token.data.data.accessToken
                },
                body: body
                
            }
        },
    }),

    /********** @subscriber **********/
    subscriber: builder.mutation({
        query: (body) => {
            return {
                url: `/subscribe`,
                responseHandler: (response) => response.json(),
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                },
                body: body
            }
        },
    }),

    /********** @get trending stories **********/
    getTrendingStories: builder.mutation({
        query: () => {
            return {
                url: `/getTrendingStories`,
                responseHandler: (response) => response.json(),
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                },
            }
        },
    }),

    /********** @save contact **********/
    createContact: builder.mutation({
        query: (body) => {
            return {
                url: `/createContactUs`,
                responseHandler: (response) => response.json(),
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                },
                body: body
            }
        },
    }),


    /************************************************
     * @tags
     ************************************************/
    getTags: builder.mutation({
        query: () => {
            return {
                url: `/getTags`,
                responseHandler: (response) => response.json(),
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }
        },
    }),


    /************************************************
     * @admin stories 
     ************************************************/
    ad_getStories: builder.mutation({
        query: () => {
            return {
                url: `/ad_getStories`,
                responseHandler: (response) => response.json(),
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: token.data.data.accessToken
                }
            }
        },
    }),

    ad_getIndividualStories: builder.mutation({
        query: (storyId) => {
            return {
                url: `/ad_getIndividualStories/${storyId}`,
                responseHandler: (response) => response.json(),
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: token.data.data.accessToken
                }
            }
        },
    }),

    ad_updateStories: builder.mutation({
        query: (body) => {
            return {
                url: `/ad_updateStories/${body.storyId}`,
                responseHandler: (response) => response.json(),
                method: "PUT",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: token.data.data.accessToken
                },
                body: body.individualStory
            }
        },
    }),


  })

// @all end
})


export const { 

    // user APIs
    useLoginMutation,
    useSignupMutation,
    useProfileMutation,
    useUpdateUserProfileMutation,
    useUserDashboardSummaryMutation,

    // website APIs
    useCreateContactMutation,
    useSubscriberMutation,

    // story APIs
    useSaveStoryMutation,
    useGetStoriesMutation,
    useIndividualStoryMutation,
    useUploadStoryPhotosMutation,
    useLikeStoryMutation,
    useGetTrendingStoriesMutation,
    useGetUserStoriesMutation,
    useCommentsOnStoryMutation,
    useCommentsListMutation,

    // tags APIs
    useGetTagsMutation,

    // admin stories
    useAd_getStoriesMutation,
    useAd_getIndividualStoriesMutation,
    useAd_updateStoriesMutation,


 } = monkServices