import React, { useEffect } from "react";
import { MainRouter } from "./Routes";
import "./App.css"

function App() {
  useEffect(() => {
    const REPLACE_TOKEN = "www.";
    if (window.location.href.includes(REPLACE_TOKEN)) {
      window.location.href = window.location.href.replace(REPLACE_TOKEN, "");
    }
  }, []);
  return <MainRouter />;
}

export default App