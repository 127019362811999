import React from 'react';
import ReactDOM, { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
// import * as serviceWorker from "./serviceWorker";
import reportWebVitals from './reportWebVitals';
import { store } from "./Redux/store";
import "./Assets/css/style.css"
import "./Assets/css/soft-design-system.css?v=1.0.5"
import { HelmetProvider } from 'react-helmet-async';

const rootElement = document.getElementById("revermonk");
const root = ReactDOM.createRoot(rootElement)

if (rootElement.hasChildNodes()) {
  hydrateRoot(
    rootElement,
    <React.StrictMode>
      <Provider store={store}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    </React.StrictMode>
  )
} else  {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    </React.StrictMode>
  )
}

reportWebVitals();
//serviceWorker.unregister();
