import React from "react";
import { Link } from "react-router-dom";
import * as imgs from "../../../Assets/img";
import './sidebar.scss';
import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags, faPodcast, faHashtag, faHome } from '@fortawesome/free-solid-svg-icons'


const RowItem = ({ id, label, icon, onSelect, route }) => (
  <li onClick={() => onSelect(id)}>
    <Link to={route}>
      <img src={icon} width={18} alt={label} />
      {label}
    </Link>
  </li>
);

class Sidebar extends React.PureComponent {
  state = {
    activeRowId: "",
    user_data: JSON.parse(localStorage.getItem('user-data')),
    hasActive: '',
    count: 1
  };

  onRowSelect = (id) => {
    this.setState({ activeRowId: id });
  };

  mapRowConfigToItem = (row, activeRowId) =>
    row.map((config) => (
      <RowItem
        key={config.id}
        isActive={config.id === activeRowId}
        onSelect={this.onRowSelect}
        {...config}
      />
    ));

  render() {

    return (
      <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl fixed-start d-md-block d-none" id="sidenav-main">
        {/* <hr className="horizontal dark mt-0" /> */}
        <div className="collapse navbar-collapse  w-auto  max-height-vh-100 h-100" id="sidenav-collapse-main">

          {/* <div className="sidenav-footer mx-3 ">
            <div className="card card-background shadow-none card-background-mask-secondary" id="sidenavCard">
              <div className="full-background" style={{backgroundImage: `url(${imgs.white_curved})`}}></div>
              <div className="card-body text-start p-3 w-100">
                <FontAwesomeIcon icon={faHandshake}  />
                <div className="docs-info">
                  <h6 className="text-white up mb-0">Need help?</h6>
                  <p className="text-xs font-weight-bold">Please check our events</p>
                  <Link to="/events">
                    <span className="btn btn-white btn-sm w-100 mb-0">Events</span>
                  </Link>
                </div>
              </div>
            </div>
            <span className="btn bg-gradient-primary mt-4 w-100">Join Free Now</span>
          </div> */}

          <ul className="navbar-nav">
            <NavLink activeclassname="active" to="/" role="button" className="nav-link link-light">
              <FontAwesomeIcon className="bi pe-none me-2" icon={faHome} />
              Home
            </NavLink>
            <NavLink activeclassname="active" to="/tags" role="button" className="nav-link link-light">
              <FontAwesomeIcon className="bi pe-none me-2" icon={faHashtag} />
              Hashtag
            </NavLink>
            <NavLink activeclassname="active" to="/tags" role="button" className="nav-link link-light">
              <FontAwesomeIcon className="bi pe-none me-2" icon={faTags} />
              Tags
            </NavLink>
            <NavLink activeclassname="active" to="/sponsers" role="button" className="nav-link link-light">
              <FontAwesomeIcon className="bi pe-none me-2" icon={faPodcast} />
              Sponsers
            </NavLink>
          </ul>

          {/* revermonk community */}
          <div className="sidenav-footer mx-3 mr-10">
            
            {/* <div className="card card-background-mask-secondary" id="sidenavCard">
              <div className="card-body text-start p-3 w-100">
                <span className="fs-14 fw-normal">Revermonk Community</span>
                <div className="docs-info mr-10">
                  <h5 className="up fs-6 ">Can chat-gpt reduce man power in IT industries?</h5>
                  <img className="mr-10" src={imgs.chatgpt} height="100" width="100%" alt="chat_bot"/>
                </div>
              </div>
            </div>

            <div className="card card-background-mask-secondary mr-10" id="sidenavCard">
              <div className="card-body text-start p-3 w-100">
                <span className="fs-14 fw-normal">Revermonk Community</span>
                <div className="docs-info mr-10">
                  <h5 className="up fs-6 ">Future of virtual reality</h5>
                  <img className="mr-10" src={imgs.metaverse} height="100" width="100%" alt="ar_vr" />
                </div>
              </div>
            </div> */}

          </div>

        </div>
      </aside> 
    )
  }
}



export default Sidebar;
